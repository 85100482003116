import BasePlugin from '../BasePlugin'

export default class ExportAdjustmentRequest extends BasePlugin {
  async execute () {
    let me = this
    let scenarioId = this.context.getModel().id
    let card = this.context.getCard()
    card.setLoading(true)

    if (this.context.getCard().data.hasOwnProperty('attr_4681_')) {
      scenarioId = this.context.getModel().attr_4681_
    }

    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/101`,
      responseType: 'arraybuffer',
      data: { 'sc_id': scenarioId }
    }).then(function (response2) {
      card.setLoading(false)
      me.openExportAttachment(response2.data, me, 'xlsx')
    }, (response) => { card.setLoading(false) })
  }
}
